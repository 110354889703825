<template>
  <div class="check-contact">
    <div class="main-contact bg-gray">
      <div class="container container-contact bg-line">
        <h4 class="container-contact__category" v-if="language && Object.keys(language).length > 0">{{language["menu::contact"][locale]}}</h4>
        <h1 class="container-contact__title" v-if="language && Object.keys(language).length > 0">
          {{language["other::namecompany"][locale]}}
        </h1>
        <div class="container-contact__content">
          <div class="list-address" v-if="data?.length > 0">
            <div class="item" v-for="(item, index) in data" :key="index">
              <h3 class="item__title">{{ item[locale]?.office_name }}</h3>
              <div class="box">
                <p class="box__title" v-if="language && Object.keys(language).length > 0">{{language["other::addressa"][locale]}}</p>
                <p class="box__content" v-html="item[locale]?.content">
                </p>
              </div>
              <div class="box">
                <p class="box__title" v-if="language && Object.keys(language).length > 0">{{language["menu::contact"][locale]}}</p>
                <p class="box__content">{{ item.phone }}</p>
              </div>
              <div class="box">
                <p class="box__title">Email</p>
                <p class="box__content email">{{ item.email }}</p>
              </div>
            </div>
            <!-- <div class="item">
              <h3 class="item__title">Văn phòng tại Hà Nội</h3>
              <div class="box">
                <p class="box__title">Địa chỉ</p>
                <p class="box__content">
                  Tầng 10 toà nhà Leadvisors 41 Lý Thái Tổ, quận Hoàn Kiếm, Hà
                  Nội, Việt Nam
                </p>
              </div>
              <div class="box">
                <p class="box__title">Liên hệ</p>
                <p class="box__content">(+84) 0889 886 086</p>
              </div>
              <div class="box">
                <p class="box__title">Email</p>
                <p class="box__content email">info@taybachoxa.vn</p>
              </div>
            </div> -->
          </div>
        </div>
        <h1 class="container-contact__title frames-title" v-if="language && Object.keys(language).length > 0" >{{language["other::locationMap"][locale]}}</h1>
      </div>
      <div class="map-contact">
        <div class="content">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3813.6602993888737!2d106.98058211529631!3d17.08926111583181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3140c7b17160bd67%3A0x89916776c97c3419!2zS0NOIEFtYmVyIFTDonkgQuG6r2MgSOG7kyBYw6E!5e0!3m2!1svi!2s!4v1661102773934!5m2!1svi!2s"
            width="100%" height="100%" style="border: 0" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
    <Contact :titleBottom="'THANK YOU!'" :language="language" />
  </div>
</template>

<script>
// import { mapMutations } from "vuex";
import Contact from "@/components/home/Contact.vue";
import ContactService from "@/services/ContactService";
import { mapGetters } from "vuex";
import CommonSerive from "@/services/CommonSerive";

export default {
  components: {
    Contact,
  },
  created() {
    this.dataHome();
  },
  data() {
    return {
      data: [],
      language: [],
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.dataContact();
  },
  computed: {
    ...mapGetters(["subActive", "locale"]),
  },
  methods: {
    async dataContact() {
      await ContactService.getListContact()
        .then((resp) => {
          this.data = resp.data.data.contactList.reverse()
        })
        .catch(() => { });
    },
    async dataHome() {
      await CommonSerive.getLang()
        .then((resp) => {
          this.language = resp.data.data
        })
        .catch(() => {});
    }
  },
};
</script>
